.country-comp-main-container {
	display: flex;
	padding: 30px 0px;
	border-top: 1px solid black;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
}

.fields-container {
	display: flex;
	width: 100%;
	padding: 0 calc((100% - 1250px) / 2);
	margin-bottom: 30px;
	justify-content: space-between;
}

.input-fields {
	background-color: white;
}

.input-fields > * > *,
.input-fields > * > * > * {
	border-color: black !important;
}

.maps-container {
	display: flex;
	width: 100%;
	margin-bottom: 30px;
	justify-content: space-between;
}
.maps-container > * {
	display: flex;
	flex-direction: column;
}

.uk-map-container {
	margin-right: auto;
	width: auto;
}

.world-map-container {
	margin-left: auto;
	width: auto;
	flex-grow: 1;
}

.export-map-button {
	margin: auto 20px 0px auto;
	width: 24px;
	height: 24px;
	cursor: pointer;
	border-radius: 6px;
	color: black !important;
	font-size: 16px !important;
	cursor: pointer;
	background-color: white !important;
	border: 1px solid black;
	box-shadow: black 5px 5px;
	display: flex;
	align-items: center;
	padding: 10px 10px;
}

.export-map-button:active {
	background-color: #fcee21 !important;
	margin-right: 18px !important;
	box-shadow: black 3px 3px;
	transform: translateY(2px) !important;
}

.map-tools-container {
	width: 100%;
	height: auto;
	display: flex;
	margin-top: 25px;
}

.export-map-button img {
	width: 100%;
	height: 100%;
}

.color-scale-container {
	display: flex;
	width: calc(100% - 46px - 70px);
	height: 100%;
	margin-right: auto;
	flex-wrap: wrap;
	font-size: 13px;
	margin-left: 20px !important;
}

#color-scale {
	border-bottom: 1px solid;
	width: 100%;
	height: calc(100% - 13px - 20px);
	/* background-image: linear-gradient(to right, #83d0e2, #006837); */
	background-image: linear-gradient(to right, #ffd4a6, #f46700);
}

#minimum-value {
	margin-top: auto;
	margin-right: auto;
	line-height: 13px;
	height: 13px;
}

#maximum-value {
	margin-top: auto;
	margin-left: auto;
	line-height: 13px;
	height: 13px;
}
.enter-inv-data-label {
	margin-bottom: 2rem;
}
@media (min-width: 1370px) {
	.maps-container {
		flex-direction: row;
		height: 620px;
	}
	.uk-map-container {
		border-right: 1px solid black;
	}

	.country-comp-main-container {
		width: 1350px;
	}
	#uk-map,
	#world-map {
		height: 600px;
	}

	#id-field-industries {
		width: 400px;
	}

	#id-field-invamount {
		width: 250px;
	}
	.enter-inv-data-label {
		display: flex;
		width: 100%;
		padding: 0 calc((100% - 1250px) / 2);
		margin-bottom: 30px;
		justify-content: space-between;
	}

	#id-field-industries {
		margin-right: 2rem;
	}
	.currency-input {
		width: 160px !important;
	}
}

@media (max-width: 1370px) {
	.fields-container {
		display: flex;
		flex-direction: column;
	}

	#id-field-industries {
		margin-bottom: 10px;
	}
	#id-field-invamount {
		margin-bottom: 10px;
	}

	.country-comp-main-container {
		width: 100%;
	}

	.maps-container {
		flex-direction: column;
	}
	#uk-map,
	#world-map {
		width: 100%;
	}

	.uk-map-container {
		width: 100%;
	}

	.world-map-container {
		margin-left: 0;
	}

	.world-map-container {
		border-top: 1px solid black;
		margin-top: 1.5rem;
	}

	.currency-form {
		width: 100%;
	}
}
