@media (min-width: 901px) {
	.footer-container {
		width: 100%;
		max-width: 1350px;
		margin: 0 auto;
		border-top: 1px solid black;
		padding: 30px 0px;
	}

	.footer-container > div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		height: 120px;
		padding: 0 calc((100% - 1250px) / 2);
	}

	.footer-left-section a > img {
		width: 100%;
		max-width: 265px;
	}

	.footer-middle-section {
		font-size: 12px;
		text-align: center;
		line-height: 25px;
		flex: 1;
	}

	.footer-middle-section a {
		color: black;
		text-decoration: none;
		margin: 0px 7px;
	}

	.footer-middle-section span {
		line-height: 10px !important;
	}

	#socials-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	#socials-container > a {
		margin: 10px;
	}

	.footer-right-section a > img {
		width: 100%;
		max-width: 265px;
	}

	.footer-left-section,
	.footer-right-section {
		width: 20%;
	}

	.footer-left-section a,
	.footer-right-section a {
		display: flex;
	}

	@media (max-width: 1400px) {
		.footer-left-section {
			margin-left: 35px;
		}
		.footer-right-section {
			margin-right: 35px;
		}
	}

	.footer-container-mobile {
		display: none;
	}
}

@media (max-width: 900px) {
	.footer-container {
		display: none;
	}

	.footer-container-mobile {
		width: 100%;
		max-width: 1350px;
		margin: 0 auto;
		border-top: 1px solid black;
		padding: 30px 0px;
	}

	.footer-container-mobile > .footer-logo-section {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding: 0 35px;
	}

	.footer-left-section a > img {
		width: 100%;
		max-width: 265px;
	}

	.footer-right-section a > img {
		width: 100%;
		max-width: 265px;
	}

	.footer-left-section,
	.footer-right-section {
		width: 40%;
	}

	.footer-left-section a,
	.footer-right-section a {
		display: flex;
	}
	.footer-middle-section {
		font-size: 12px;
		text-align: center;
		line-height: 25px;
		margin-top: 25px;
		flex: 1;
	}

	.footer-middle-section a {
		color: black;
		text-decoration: none;
		margin: 0px 7px;
	}

	#socials-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	#socials-container > a {
		margin: 10px;
	}
}
