.by-alma a {
	text-decoration: none;
	color: black;
}

@media (min-width: 1370px) {
	.header-container {
		background-color: white !important;
		width: 100%;
		height: 120px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.logo-image {
		height: 50px;
	}

	.logo-container {
		margin-right: auto;
		padding-left: calc((100% - 1250px) / 2);
	}

	.tabs-container {
		margin-left: auto;
		padding-right: calc((100% - 1250px) / 2);
	}

	.header-button {
		padding: 10px 20px;
		border-radius: 6px;
		height: 50px;
		font-size: 16px;
		margin-left: 15px;
		cursor: pointer;
		background-color: white;
		border: 1px solid black;
		box-shadow: black 5px 5px;
	}

	.header-button:hover {
		background-color: transparent;
	}

	.header-button-active,
	.header-button:active {
		background-color: #fcee21 !important;
		margin-left: 17px !important;
		margin-right: -2px !important;
		box-shadow: black 3px 3px;
		transform: translateY(2px);
		padding: 10px 20px;
		border-radius: 6px;
		height: 50px;
		border: 1px solid black;
		font-size: 16px;
		cursor: pointer;
	}

	.header-border {
		position: absolute;
		background-color: black;
		height: 1px;
		max-width: calc(100% - (100% - 1350px));
		width: 100vw;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		top: 120px;
	}

	.by-alma {
		position: absolute;
		top: 130px;
		padding-left: calc((100% - 1250px) / 2);
		font-size: 12px;
	}

	.header-container-mobile {
		display: none;
	}
}

@media (max-width: 1370px) {
	.header-container {
		display: none;
	}

	.header-container-mobile {
		background-color: white;
		width: 100%;
		padding: 20px 0px !important;
		height: 40px;
		display: flex;
	}

	.header-container-mobile ul {
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
		background-color: white;
	}

	.header-container-mobile li a {
		display: block;
		padding: 25px 30px;
		text-decoration: none;
		color: black !important;
	}

	.header-container-mobile li:nth-child(3) {
		border-bottom: 1px solid black;
	}

	.logo-image-mobile {
		display: flex;
		max-width: 60%;
		height: 45px;
		margin-left: 30px;
		margin-right: auto;
	}
	.logo-image-mobile img {
		width: 100%;
	}

	.header-container-mobile li a:hover,
	.header-container-mobile .menu-btn:hover {
		background-color: #fcee21;
	}

	.header-container-mobile .logo {
		display: block;
		float: left;
		padding: 10px 20px;
		text-decoration: none;
	}

	.header-container-mobile .menu {
		clear: both;
		max-height: 0;
		transition: max-height 0.2s ease-out;
		position: absolute;
		z-index: 10;
		width: 100%;
		left: 0;
		margin-top: 61px;
	}

	.header-container-mobile .menu-icon {
		cursor: pointer;
		float: right;
		padding: 22px 35px 22px 0px;
		position: relative;
		-webkit-user-select: none;
		user-select: none;
	}

	.header-container-mobile .menu-icon .navicon {
		background: black;
		display: block;
		height: 3px;
		position: relative;
		transition: background 0s ease-out;
		width: 25px;
	}

	.header-container-mobile .menu-icon .navicon:before,
	.header-container-mobile .menu-icon .navicon:after {
		background: black;
		content: "";
		display: block;
		height: 100%;
		position: absolute;
		transition: all 0.2s ease-out;
		width: 100%;
	}

	.header-container-mobile .menu-icon .navicon:before {
		top: 8px;
	}

	.header-container-mobile .menu-icon .navicon:after {
		top: -8px;
	}

	.header-container-mobile .menu-btn {
		display: none;
	}

	.header-container-mobile .menu-btn:checked ~ .menu {
		max-height: 240px;
	}

	.header-container-mobile .menu-btn:checked ~ .menu-icon .navicon {
		background: transparent;
	}

	.header-container-mobile .menu-btn:checked ~ .menu-icon .navicon:before {
		transform: rotate(-45deg);
	}

	.header-container-mobile .menu-btn:checked ~ .menu-icon .navicon:after {
		transform: rotate(45deg);
	}

	.header-container-mobile
		.menu-btn:checked
		~ .menu-icon:not(.steps)
		.navicon:before,
	.header-container-mobile
		.menu-btn:checked
		~ .menu-icon:not(.steps)
		.navicon:after {
		top: 0;
	}

	.header-border {
		position: absolute;
		background-color: black;
		height: 1px;
		max-width: calc(100% - (100% - 1350px));
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		top: 80px;
		z-index: 1;
	}

	.by-alma {
		position: absolute;
		top: 90px;
		padding-left: calc((100% - 1250px) / 2);
		z-index: 1;
		margin-left: 35px;
	}
}
