.top-container {
	display: flex;
	padding: 60px calc((100% - 1250px) / 2);
}

.left-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: auto;
	width: 60%;
}

.left-container div {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

#page-title {
	font-size: 30px;
}

#page-moto {
	font-size: 68px;
	line-height: 65px;
	word-break: break-word;
}

#page-subtitle {
	font-size: 26px;
	line-height: 35px;
}

.right-container {
	position: absolute;
	width: 300px;
	height: 300px;
	right: calc(((100% - 1250px) / 2) + 5%);
}

.middle-container {
	padding: 0 calc((100% - 1250px) / 2);
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 70px;
	text-align: justify;
	-webkit-hyphens: auto;
	hyphens: auto;
	column-count: 2;
	column-gap: 40px;
}

.middle-container-mobile {
	display: none;
}

.bottom-container {
	display: flex;
	padding: 0 calc((100% - 1250px) / 2);
	flex-direction: column;
	font-size: 30px;
	margin-bottom: 100px;
}

#available-tools-title {
	margin-bottom: 25px;
}

.button-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.bottom-container .button-container > button {
	border-radius: 6px;
	width: 32%;
	height: 60px;
	font-size: 16px;
	cursor: pointer;
	background-color: white;
	border: 1px solid black;
	box-shadow: black 5px 5px;
}

.bottom-container .button-container > button:active {
	background-color: #fcee21 !important;
	margin-left: 2px !important;
	margin-right: -2px !important;
	box-shadow: black 3px 3px;
	transform: translateY(2px);
}

@media (max-width: 1370px) {
	.left-container {
		width: 100% !important;
		margin-right: 350px;
	}
}

@media (max-width: 650px) {
	.button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.button-container button {
		width: 100% !important;
		margin: 10px 0px;
	}
}

@media (max-width: 900px) {
	#page-title {
		margin-bottom: 225px !important;
	}
	#page-moto {
		margin-top: 25px;
	}

	.top-container {
		padding-bottom: 15px !important;
	}
	.left-container {
		margin-top: 180px;
		margin-right: 0px;
	}
	.middle-container {
		display: none;
	}
	.middle-container-mobile {
		display: block;
		flex-direction: row;
		padding: 0 calc((100% - 1250px) / 2);
		font-size: 16px;
		line-height: 25px;
		margin-bottom: 70px;
	}
}
