@font-face {
	font-family: "Archia";
	src: local("Archia-Regular"),
		url("../fonts/Archia-Regular.otf") format("opentype");
}

.description-text-container {
	margin-bottom: 2rem;
}

.main-container {
	margin: 2rem 4rem;
}

.table-container {
	margin-top: 4rem;
}

.calc-table {
	margin: 2rem 50px 0px;
}

.percentage-container {
	background-color: #f4f4f0;
}

.user-initial-input {
	border-top: 1px solid black;
}

.table-input {
	background-color: white !important;
}

.percentage-label {
	font-size: 13px;
	margin-bottom: 0.3rem;
}

.export-table-button {
	margin-bottom: 10rem;
	padding: 10px 20px;
	border-radius: 6px;
	width: 95px;
	color: black !important;
	font-size: 16px !important;
	cursor: pointer;
	background-color: white !important;
	border: 1px solid black;
	box-shadow: black 5px 5px;
	display: flex;
	align-items: center;
	margin-top: 3rem;
}

.export-table-button:active {
	background-color: #fcee21 !important;
	margin-left: 2px !important;
	box-shadow: black 3px 3px;
	transform: translateY(2px);
}

.export-table-button img {
	width: 24px;
	height: 24px;
	margin-left: 13px !important;
}

#employment-multipliers-select-label {
	font-family: "Archia";
}
#enter-inv-data {
	font-size: 30px;
	margin-bottom: 2rem;
	margin-top: 2rem;
}
@media (min-width: 1300px) {
	#enter-inv-data {
		margin-left: 3rem;
	}
	.first-row-input {
		margin-left: 3rem;
		margin-bottom: 2rem;
	}
	.percentage-container {
		margin-bottom: 2rem;
		margin-left: 3rem;
	}

	#middle-input {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.export-table-button {
		margin-left: 3rem !important;
	}

	.export-table-button:active {
		background-color: #fcee21 !important;
		margin-left: calc(3rem + 2px) !important;
		box-shadow: black 3px 3px;
		transform: translateY(2px);
	}
}

@media (max-width: 1300px) {
	.table-container {
		width: 100%;
	}

	.table-container > * {
		margin: 0;
	}

	.first-row-input {
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;
	}
	.first-row-input > * {
		margin-bottom: 10px !important;
	}

	#middle-input {
		margin-bottom: 10px !important;
	}

	#first-percentage {
		margin-bottom: 10px !important;
	}

	#second-percentage {
		margin-bottom: 2rem !important;
	}
}

.align-contect-center > * {
	text-align: center !important;
}

.info-description a {
	color: black !important;
}
