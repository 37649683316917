.form-container {
	display: flex;
	padding: 30px 0px;
	border-top: 1px solid black;
	flex-wrap: wrap;
	flex-direction: column;
}

.form-top-row {
	display: flex;
	/* margin-bottom: 20px; */
}

.enter-inv-data-label {
	font-size: 30px;
}

.form-container .form-top-row .form-currency-select {
	margin-left: auto;
	margin-right: 44px;
	max-width: 160px;
	width: 45%;
	height: 40px;
}

#nat-reg-enter-inv-data-label {
	margin-bottom: 10px !important;
}

.form-container .form-top-row .form-currency-select > * {
	width: 100%;
}

.form-main {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

#form-row {
	display: flex;
	flex-direction: row;
}

.form-main > div > form {
	max-width: 1350px !important;
	margin-bottom: 30px;
}

.form-cell-first {
	width: 100%;
	margin: 0px 3px 0px 20px !important;
	min-width: 120px !important;
}

.form-cell {
	width: 100%;
	margin: 0px 3px !important;
	min-width: 120px !important;
}

.form-cell-last {
	width: 100% !important;
	margin: 0px 20px 0px 3px !important;
	min-width: 120px !important;
}

.form-cell > * > *,
.form-cell-last > * > *,
.form-currency-select > * > * {
	border-color: black !important;
}

.form-row-number {
	font-size: 35px;
	min-height: 38px !important;
	min-width: 24px !important;
	margin: 0px !important;
}

.form-row-remove-action {
	min-height: 38px !important;
	min-width: 24px !important;
	margin: 0px !important;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.form-row-remove-action img,
.form-row-remove-action-mobile img {
	width: 24px;
	height: 24px;
	margin-left: auto;
}

.form-rowactions-container {
	display: flex;
	margin: 0px 20px;
}

.form-rowactions-add {
	margin-right: auto;
}

.form-rowactions-remove,
.form-rowactions-add {
	color: #757575;
	cursor: pointer;
}

.form-rowactions-remove:hover,
.form-rowactions-add:hover {
	text-decoration: underline;
	color: black;
}

.remove-row-button,
.add-row-button {
	width: 40px !important;
	height: 40px !important;
	margin: 0px !important;
}

.form-bottom-row {
	display: flex;
	width: 150px;
	margin-right: auto;
	margin-top: 20px;
	align-items: center;
	cursor: pointer;
	height: 50px;
}

.form-add-row-icon {
	width: 24px;
	height: 24px;
}

.form-add-row-text {
	font-size: 16px;
	margin-left: 26px;
}

.form-add-row-text:hover {
	text-decoration: underline;
}

.empty-field > * > * > *,
.empty-field > * > * > * > * {
	border-color: #e8666d !important;
}

.table-container {
	display: flex;
	padding: 30px 0px;
	border-top: 1px solid black;
	height: 100%;
	margin-top: 0px !important;
	flex-direction: column;
}

.datagrid-container {
	height: 100%;
	width: 100%;
}

.export-button {
	border-radius: 6px;
	height: 24px;
	width: 95px;
	color: black !important;
	font-size: 16px !important;
	cursor: pointer;
	background-color: white !important;
	border: 1px solid black;
	box-shadow: black 5px 5px;
	display: flex;
	align-items: center;
	margin-left: 0px;
	padding: 10px 20px;
}

.export-button:active {
	background-color: #fcee21 !important;
	margin-left: 2px !important;
	box-shadow: black 3px 3px;
	transform: translateY(2px);
}

.highcharts-button-symbol {
	stroke: black !important;
}

.highcharts-xaxis-labels text {
	word-break: keep-all;
}

.highcharts-credits {
	color: transparent !important;
	fill: transparent !important;
}

.export-button img {
	width: 24px;
	height: 24px;
	margin-left: 13px !important;
}

#hide-this {
	visibility: hidden !important;
	height: 0px !important;
	width: 0px !important;
	z-index: -10;
}

.charts-container {
	border-top: 1px solid black;
	padding: 30px 0px;
}

.small-charts-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-evenly;
	margin-top: 20px;
}

.chart {
	margin-bottom: 20px;
	width: 550px !important;
}

.form-row-mobile-buttons {
	display: none;
}

@media (max-width: 1370px) {
	#form-row {
		flex-wrap: wrap;
	}
	#form-row > * {
		margin: 0px !important;
		margin-bottom: 10px !important;
	}

	.form-row-number,
	.form-row-remove-action {
		display: none;
	}

	.form-row-mobile-buttons {
		display: flex;
		width: 100% !important;
		margin: 0px 20px !important;
		padding-bottom: 5px;
	}

	.form-row-number-mobile {
		font-size: 35px;
		min-height: 38px !important;
		min-width: 24px !important;
		margin: 0px !important;
		margin-right: auto !important;
		padding-left: 11px !important;
	}

	.form-row-remove-action-mobile {
		min-height: 38px !important;
		min-width: 24px !important;
		margin: 0px !important;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	.form-bottom-row {
		margin-top: 0px !important;
	}

	.form-container .form-top-row .form-currency-select {
		margin-right: 0px;
	}
}

@media (max-width: 780px) {
	.form-container .form-top-row .form-currency-select {
		margin-left: auto;
	}

	#nat-reg-enter-inv-data-label {
		margin-right: auto;
		width: 45% !important;
	}
}

.table-tools {
	margin-top: 20px;
	display: flex;
}

.table-summed-data {
	display: flex;
	flex-direction: column;
	margin-right: auto;
	padding-right: 50px;
}

.table-summed-data span {
	margin: 10px 0px;
}

@media (max-width: 545px) {
	.table-summed-data {
		width: 230px !important;
	}
}
