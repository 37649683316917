.right-container img {
	position: absolute;
	opacity: 0;
	animation: FadeInOut 1.5s normal both;
	width: 100%;
}

.right-container img:nth-child(1) {
	z-index: 1 !important;
	animation-delay: 0.45s;
}

.right-container img:nth-child(2) {
	z-index: 2 !important;
	animation-delay: 0.9s;
}

.right-container img:nth-child(3) {
	z-index: 3 !important;
	animation-delay: 1.35s;
}

.right-container img:nth-child(4) {
	z-index: 4 !important;
	animation-delay: 1.8s;
}

.right-container img:nth-child(5) {
	z-index: 5 !important;
	animation-delay: 2.25s;
}

.right-container img:nth-child(6) {
	z-index: 6 !important;
	animation-delay: 2.7s;
}

.right-container img:nth-child(7) {
	animation: FadeIn 0.75s normal both !important;
	z-index: 7 !important;
	animation-delay: 3.15s !important;
}

@keyframes FadeInOut {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
		visibility: hidden;
	}
}

@keyframes FadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media (max-width: 1370px) {
	.right-container {
		right: 35px !important;
		margin-left: 20px !important;
	}
}

@media (max-width: 900px) {
	.right-container {
		top: 150px;
		width: 200px;
		left: 32px !important;
		right: 0px !important;
	}
}
