@font-face {
	font-family: "Archia";
	src: local("Archia-Regular"),
		url("../fonts/Archia-Regular.otf") format("opentype");
}

.page-information-container .info-description p {
	font-size: 12px;
	line-height: 20px;
	padding-top: 15px;
}
* {
	font-family: "Archia", sans-serif;
}

body {
	margin: 0;
	font-family: "Archia", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f4f4f0;
}

fieldset {
	color: black !important;
	border-color: black !important;
}

@media (max-width: 1370px) {
	.page-container {
		margin: 35px 35px 0px;
	}
	.page-information-container {
		padding: 60px calc((100% - 1250px) / 2);
	}

	.page-information-container .page-title {
		width: 100%;
		font-size: 2.9rem;
		line-height: 55px;
		margin-bottom: 1rem;
	}

	.page-information-container .info-label {
		margin-bottom: 1rem;
	}

	.page-information-container .info-description {
		text-align: left;
		font-size: 16px;
		line-height: 21px;
		overflow-wrap: break-word;
	}
}

@media (min-width: 1370px) {
	.page-container {
		width: 1350px;
		margin: 35px auto 0px;
	}

	.page-information-container {
		display: flex;
		padding: 60px calc((100% - 1250px) / 2);
		width: 1250px;
	}

	.page-information-container .page-title {
		width: 25%;
		font-size: 2.9rem;
	}

	.page-information-container .info-label {
		padding-left: 3%;
	}

	.page-information-container .info-divider {
		width: 1px;
		height: auto;
		background-color: black;
		margin-left: 25px;
	}

	.page-information-container .info-description {
		text-align: left;
		width: 70%;
		padding-left: 2%;
		font-size: 16px;
		line-height: 21px;
	}
}
